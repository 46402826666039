
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.current-portal-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;

  &__img {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: auto;
    max-width: 24px;
    max-height: 24px;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
